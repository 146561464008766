<template>
  <div class="page">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onRefresh()" >同步刷新抖音门店</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="商户名称" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.poiName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="地址" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.address || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.poiStatus ? "正常" : "新建" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关联门店" align="center" >
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">修改关联门店</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-radio-group v-model="params.shopId">
            <el-row :gutter="20">
              <el-col class="shop-item" :span="8" v-for="item in shopList" :key="item.shopId">
                <el-radio :label="item.shopId">{{ item.shopName }}</el-radio>
              </el-col>
            </el-row>
          </el-radio-group>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTableList,
  getRefreshList,
  getTableEdit,
} from "@/api/group/shop";
import {
  getShopList,
} from "@/api/common";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        tabType: "1"
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      shopList: [],
      rules,
      params: {
        shopId: "",
      },
      // 美团授权
      autoShow: false,
      autoIframe: "https://e.dianping.com/dz-open/merchant/auth?app_key=9e798f1c99b6a083&state=teststate"
    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getTableList();// 【请求】表格数据
    this.getShopList();// 【请求】店铺列表
  },
  methods: {
    // 【请求】表格数据
    getTableList() {
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】店铺列表
    getShopList() {
      let data = {
        merchantId: this.tableParams.merchantId
      };
      getShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.shopList = res.data;
        }
      });
    },

    // 【请求】修改绑定
    getTableEdit() {
      let data = this.params;
      getTableEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.getTableList();// 【请求】表格数据
        }
      });
    },


    // 【请求】表格数据
    getRefreshList() {
      let data = this.tableParams;
      getRefreshList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.reload();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】授权
    onAuto() {
      this.autoShow = true;
    },

    // 【监听】详情
    onInfo(row) {
      console.log('row: ', row);
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "修改关联门店";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】同步
    onRefresh() {
      this.getRefreshList()
    },

    // 切换tab
    onTab(tab) {
      console.log('tab: ', tab);
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getTableEdit();
        } else {
          return false;
        }
      });
    },

  }
}
</script>


<style lang="scss" scoped>
.page {
  padding: .15rem;
  background: #fff;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.el-radio-group {
  width: 100%;
}

.shop-item {
  margin: .1rem 0;
}
</style>